var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
var GET_DISTRIBUTIONS_EVENT = 'getDistributionsEvent';
var GET_DISTRIBUTIONS_MEMBERS_EVENT = 'getDistributionsMembersEvent';
var GET_DISTRIBUTIONS_CATEGORIES_EVENT = 'getDistributionsCategoriesEvent';
var GET_PROFILES_EVENT = 'getProfilesEvent';
var GET_PROFILES_BY_ID_EVENT = 'getProfilesById';
var CREATE_DISTRIBUTION_EVENT = 'createDistributionEvent';
var CREATE_PROFILE_EVENT = 'createProfileEvent';
var UPDATE_PROFILE_EVENT = 'UpdateProfileEvent';
var UPDATE_DISTRIBUTION_EVENT = 'updateDistributionEvent';
var DistributionAndProfileStore = /** @class */ (function (_super) {
    __extends(DistributionAndProfileStore, _super);
    function DistributionAndProfileStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    DistributionAndProfileStore.prototype.initializeState = function () {
        this._distributions = [];
        this._distributionsMembers = [];
        this._distributionsCategories = [];
        this._profiles = [];
        this._profileUsers = [];
        this._profilePermissionsList = [];
        this._allPermissions = [];
        this.profileId = 0;
        this.distributionId = 0;
        this.initDistributionById = false;
        this.initProfileById = false;
    };
    DistributionAndProfileStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(DistributionAndProfileStore.prototype, "Distributions", {
        get: function () {
            return this._distributions;
        },
        set: function (distributions) {
            this._distributions = distributions;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DistributionAndProfileStore.prototype, "DistributionsMembers", {
        get: function () {
            return this._distributionsMembers;
        },
        set: function (distributionsMembers) {
            this._distributionsMembers = distributionsMembers;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DistributionAndProfileStore.prototype, "DistributionsCategories", {
        get: function () {
            return this._distributionsCategories;
        },
        set: function (distributionsCategories) {
            this._distributionsCategories = distributionsCategories;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DistributionAndProfileStore.prototype, "Profiles", {
        get: function () {
            return this._profiles;
        },
        set: function (profiles) {
            this._profiles = profiles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DistributionAndProfileStore.prototype, "ProfileUsers", {
        get: function () {
            return this._profileUsers;
        },
        set: function (profileUsers) {
            this._profileUsers = profileUsers;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DistributionAndProfileStore.prototype, "ProfilePermissionsList", {
        get: function () {
            return this._profilePermissionsList;
        },
        set: function (profilePermissions) {
            this._profilePermissionsList = profilePermissions;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DistributionAndProfileStore.prototype, "AllPermissions", {
        get: function () {
            return this._allPermissions;
        },
        set: function (allPermissions) {
            this._allPermissions = allPermissions;
        },
        enumerable: false,
        configurable: true
    });
    DistributionAndProfileStore.prototype.getProfileId = function () {
        return this.profileId;
    };
    DistributionAndProfileStore.prototype.setProfileId = function (profileId) {
        this.profileId = profileId;
    };
    DistributionAndProfileStore.prototype.getDistributionId = function () {
        return this.distributionId;
    };
    DistributionAndProfileStore.prototype.setDistributionId = function (distributionId) {
        this.distributionId = distributionId;
    };
    DistributionAndProfileStore.prototype.updateProfile = function (data) {
        var res = this.profiles.map(function (profile) {
            return profile.id === data.Id ? __assign(__assign({}, profile), { name: data.Name }) : profile;
        });
        this.profiles = res;
    };
    DistributionAndProfileStore.prototype.updateDistribution = function (data) {
        var res = this.distributions.map(function (distribution) {
            return distribution.groupId === data.Id
                ? __assign(__assign({}, distribution), { groupName: data.Name }) : distribution;
        });
        this.distributions = res;
    };
    DistributionAndProfileStore.prototype.getInitDistributionById = function () {
        return this.initDistributionById;
    };
    DistributionAndProfileStore.prototype.setInitDistributionById = function (value) {
        this.initDistributionById = value;
    };
    DistributionAndProfileStore.prototype.getInitProfilById = function () {
        return this.initProfileById;
    };
    DistributionAndProfileStore.prototype.setInitProfilById = function (value) {
        this.initProfileById = value;
    };
    DistributionAndProfileStore.prototype.emitGetDistributions = function (type) {
        this.emit(GET_DISTRIBUTIONS_EVENT, type);
    };
    DistributionAndProfileStore.prototype.addGetDistributionsListener = function (callback) {
        this.on(GET_DISTRIBUTIONS_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeGetDistributionsListener = function (callback) {
        this.removeListener(GET_DISTRIBUTIONS_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.emitGetDistributionsMembers = function () {
        this.emit(GET_DISTRIBUTIONS_MEMBERS_EVENT);
    };
    DistributionAndProfileStore.prototype.addGetDistributionsMembersListener = function (callback) {
        this.on(GET_DISTRIBUTIONS_MEMBERS_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeDistributionsMembersListener = function (callback) {
        this.removeListener(GET_DISTRIBUTIONS_MEMBERS_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.emitGetDistributionsCategories = function () {
        this.emit(GET_DISTRIBUTIONS_CATEGORIES_EVENT);
    };
    DistributionAndProfileStore.prototype.addGetDistributionsCategoriesListener = function (callback) {
        this.on(GET_DISTRIBUTIONS_CATEGORIES_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeGetDistributionsCategoriesListener = function (callback) {
        this.removeListener(GET_DISTRIBUTIONS_CATEGORIES_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.emitCreateDistribution = function () {
        this.emit(CREATE_DISTRIBUTION_EVENT);
    };
    DistributionAndProfileStore.prototype.addCreateDistributionListener = function (callback) {
        this.on(CREATE_DISTRIBUTION_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeCreateDistributionsListener = function (callback) {
        this.removeListener(CREATE_DISTRIBUTION_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.emitCreateProfile = function () {
        this.emit(CREATE_PROFILE_EVENT);
    };
    DistributionAndProfileStore.prototype.addCreateProfileListener = function (callback) {
        this.on(CREATE_PROFILE_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeCreateProfileListener = function (callback) {
        this.removeListener(CREATE_PROFILE_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.emitUpdateProfile = function () {
        this.emit(UPDATE_PROFILE_EVENT);
    };
    DistributionAndProfileStore.prototype.addUpdateProfileListener = function (callback) {
        this.on(UPDATE_PROFILE_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeUpdateProfileListener = function (callback) {
        this.removeListener(UPDATE_PROFILE_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.emitUpdateDistribution = function () {
        this.emit(UPDATE_DISTRIBUTION_EVENT);
    };
    DistributionAndProfileStore.prototype.addUpdateDistributionListener = function (callback) {
        this.on(UPDATE_DISTRIBUTION_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeUpdateDistributionListener = function (callback) {
        this.removeListener(UPDATE_DISTRIBUTION_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.emitGetProfiles = function () {
        this.emit(GET_PROFILES_EVENT);
    };
    DistributionAndProfileStore.prototype.addGetProfilesListener = function (callback) {
        this.on(GET_PROFILES_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeGetProfilesListener = function (callback) {
        this.removeListener(GET_PROFILES_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.emitGetProfileById = function () {
        this.emit(GET_PROFILES_BY_ID_EVENT);
    };
    DistributionAndProfileStore.prototype.addGetProfileByIdListener = function (callback) {
        this.on(GET_PROFILES_BY_ID_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.removeGetProfileByIdListener = function (callback) {
        this.removeListener(GET_PROFILES_BY_ID_EVENT, callback);
    };
    DistributionAndProfileStore.prototype.registerToActions = function (payload) {
        var _a, _b, _c, _d, _e;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_DISTRIBUTIONS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.distributions = data;
                    if (this.distributionId === 0) {
                        this.setDistributionId(data[0].groupId);
                    }
                    else {
                        this.setDistributionId(this.distributionId);
                    }
                    this.initialized = true;
                    this.emitGetDistributions();
                }
                else {
                    this.emitError('GET_DISTRIBUTIONS');
                }
                break;
            case ActionTypes.GET_DISTRIBUTIONS_MEMBERS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.distributionsMembers = data;
                    this.initDistributionById = true;
                    this.emitGetDistributionsMembers();
                }
                else {
                    this.emitError('GET_DISTRIBUTIONS_MEMBERS');
                }
                break;
            case ActionTypes.GET_DISTRIBUTIONS_CATEGORIES_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.distributionsCategories = data;
                    this.initDistributionById = true;
                    this.emitGetDistributionsCategories();
                }
                else {
                    this.emitError('GET_DISTRIBUTIONS_CATEGORIES');
                }
                break;
            case ActionTypes.GET_PROFILES_RESPONSE:
                if (action.json) {
                    var _f = action.json.data, profiles = _f.profiles, roleFamilies = _f.roleFamilies;
                    var res = profiles === null || profiles === void 0 ? void 0 : profiles.filter(function (elm) { return elm.id !== 0; });
                    this.profiles = res;
                    this.allPermissions = roleFamilies;
                    if (this.profileId === 0) {
                        this.setProfileId(res[0].id);
                    }
                    else {
                        this.setProfileId(this.profileId);
                    }
                    this.initialized = true;
                    this.emitGetProfiles();
                }
                else {
                    this.emitError('GET_PROFILES');
                }
                break;
            case ActionTypes.GET_PROFILE_BY_ID_RESPONSE:
                if (action.json) {
                    var _g = action.json.data, users = _g.users, roles = _g.roles;
                    this.profileUsers = users;
                    this.profilePermissionsList = roles;
                    this.initProfileById = true;
                    this.emitGetProfileById();
                }
                else {
                    this.emitError('GET_PROFILE_BY_ID');
                }
                break;
            case ActionTypes.CREATE_PROFILE_RESPONSE:
                if (action.json) {
                    var data = action.json.data.data;
                    var model = action.json.model;
                    if (data) {
                        if (data.name) {
                            this.profiles = __spreadArray(__spreadArray([], this.profiles, true), [data], false);
                        }
                        else {
                            this.profiles = __spreadArray(__spreadArray([], this.profiles, true), [
                                {
                                    id: data.id,
                                    isAdministartor: data.isAdministartor,
                                    name: model.name,
                                },
                            ], false);
                        }
                        this.emitCreateProfile();
                        this.emitGetProfiles();
                    }
                    else {
                        this.emitError('CREATE_PROFILE', (_a = action.errors) === null || _a === void 0 ? void 0 : _a.error);
                    }
                }
                else {
                    this.emitError('CREATE_PROFILE', (_b = action.errors) === null || _b === void 0 ? void 0 : _b.error);
                }
                break;
            case ActionTypes.UPDATE_PROFILE_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    this.updateProfile(model);
                    this.emitUpdateProfile();
                    this.emitGetProfiles();
                }
                else {
                    this.emitError('UPDATE_PROFILE', (_c = action.errors) === null || _c === void 0 ? void 0 : _c.error);
                }
                break;
            case ActionTypes.DELETE_PROFILE_RESPONSE:
                if (action.json) {
                    var model_1 = action.json.model;
                    this.profiles = __spreadArray([], this.profiles.filter(function (elm) { return elm.id !== model_1.profileId; }), true);
                    this.emitGetProfiles();
                }
                else {
                    this.emitError('DELETE_PROFILE');
                }
                break;
            case ActionTypes.CREATE_DISTRIBUTION_RESPONSE:
                if (action.json) {
                    var data = action.json.data.data;
                    if (data) {
                        this.distributions = __spreadArray(__spreadArray([], this.distributions, true), [
                            { groupId: data.id, groupName: data.name, membersCount: 0 },
                        ], false);
                        this.emitGetDistributions();
                        this.emitCreateDistribution();
                    }
                    else {
                        this.emitError('CREATE_DISTRIBUTION');
                    }
                }
                else {
                    this.emitError('CREATE_DISTRIBUTION', (_d = action.errors) === null || _d === void 0 ? void 0 : _d.error);
                }
                break;
            case ActionTypes.UPDATE_DISTRIBUTION_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    this.updateDistribution(model);
                    this.emitUpdateDistribution();
                    this.emitGetDistributions();
                }
                else {
                    this.emitError('UPDATE_DISTRIBUTION', (_e = action.errors) === null || _e === void 0 ? void 0 : _e.error);
                }
                break;
            case ActionTypes.DELETE_DISTRIBUTION_RESPONSE:
                if (action.json) {
                    var model_2 = action.json.model;
                    this.distributions = __spreadArray([], this.distributions.filter(function (elm) { return elm.groupId !== model_2.groupId; }), true);
                    this.emitGetDistributions();
                }
                else {
                    this.emitError('DELETE_DISTRIBUTION');
                }
                break;
            case ActionTypes.DELETE_GROUP_MEMBER_RESPONSE:
                if (action.json) {
                    var model_3 = action.json.model;
                    this.distributionsMembers = __spreadArray([], this.distributionsMembers.filter(function (elm) {
                        return !elm.groups.includes(model_3.GroupId) &&
                            elm.userId !== model_3.UserId;
                    }), true);
                    this.emitGetDistributionsMembers();
                }
                else {
                    this.emitError('DELETE_GROUP_MEMBER');
                }
                break;
            case ActionTypes.DELETE_USER_PROFILE_RESPONSE:
                if (action.json) {
                    var model_4 = action.json.model;
                    this.profileUsers = __spreadArray([], this.profileUsers.filter(function (elm) { return elm.userId !== model_4.UserId; }), true);
                    this.emitGetProfileById();
                }
                else {
                    this.emitError('DELETE_USER_PROFILE');
                }
                break;
            case ActionTypes.UPDATE_USER_PROFILE_RESPONSE:
                if (action.json) {
                    var model_5 = action.json.model;
                    if (model_5.ProfileId !== this.profileId) {
                        this.profileUsers = __spreadArray([], this.profileUsers.filter(function (elm) { return elm.userId !== model_5.UserId; }), true);
                    }
                    this.emitGetProfileById();
                }
                else {
                    this.emitError('UPDATE_USER_PROFILE');
                }
                break;
            default:
                break;
        }
    };
    return DistributionAndProfileStore;
}(BaseStore));
export default new DistributionAndProfileStore();
