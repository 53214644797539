var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { BsGear } from 'react-icons/bs';
import { AiOutlineSearch, AiOutlineQuestionCircle } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { IoMdColorWand } from 'react-icons/io';
import Svg from 'commons/Svg';
import Checkbox from 'commons/Checkbox';
import Input from 'commons/Input';
import Typography from 'commons/Typography';
import Textarea from 'commons/Textarea';
import { Hidden } from 'commons';
import Button from 'components/Button';
import ButtonIcon from 'commons/ButtonIcon';
import parameters from 'constants/parameters';
import AdvancedFilter from 'new-components/AdvancedFilters';
import QueryBuilder from 'new-components/QueryBuilderConfiguration';
import { FilterContext } from 'context/FilterContext';
import { AdvancedFilterProvider } from 'context/AdvancedFilterContext';
import UserStore from 'stores/UserStore';
import ClipStore from 'stores/ClipStore';
import SearchStore from 'stores/SearchStore';
import FacetStore from 'stores/FacetStore';
import PermissionStore from 'stores/PermissionStore';
import SearchActionCreators from 'actions/SearchActionCreators';
import FacetConfiguration from './components/FacetsConfiguration';
import Facets from './components/Facets';
import FavouriteSearch from './components/FavouriteSearch';
import AdvancedSearchModal from '../QueryBuilderConfiguration/AdvancedSearchModal';
import { GlobalSearchbarVars, Wrapper } from './SearchBar.styles';
var TIMEOUT_BEFORE_FILTER_UPDATE = parameters.TIMEOUT_BEFORE_FILTER_UPDATE;
var ROLES_ENUM = parameters.ROLES_ENUM;
var initialState = {
    'headlines.text': false,
    'summary.text': false,
    fullText: false,
};
function SearchBar(_a) {
    var lastPeriod = _a.lastPeriod;
    var location = useLocation();
    var formatMessage = useIntl().formatMessage;
    var _b = useContext(FilterContext), isFilterOpen = _b.isFilterOpen, setIsFilterOpen = _b.setIsFilterOpen;
    var page = location.pathname.replace('/', '');
    var interval = useRef(null);
    var lastView = useRef(page);
    var searchBarRef = useRef(null);
    var queryBuilderRef = useRef(null);
    var _c = useState(''), Q = _c[0], setQ = _c[1];
    var _d = useState(window.innerWidth <= 1250), superPosed = _d[0], setSuperPosed = _d[1];
    var _e = useState(false), setRerender = _e[1];
    var _f = useState(false), isAdvancedSearchKeywords = _f[0], setIsAdvancedSearchKeywords = _f[1];
    var _g = useState(initialState), searchTypes = _g[0], setSearchTypes = _g[1];
    var _h = useState(false), openAdvancedSearchModal = _h[0], setOpenAdvancedSearch = _h[1];
    useEffect(function () {
        var _a;
        SearchStore.setLastPeriod(lastPeriod);
        if (((_a = SearchStore.getQFields()) === null || _a === void 0 ? void 0 : _a.length) > 0)
            setIsAdvancedSearchKeywords(function (prevState) { return !prevState; });
        window.addEventListener('resize', onWindowResize);
        SearchStore.addChangeListener(onSearchUpdate);
        SearchStore.addUpdateFiltersListener(onUpdateFilter);
        SearchStore.addErrorListener(onError);
        FacetStore.addFacetsUpdateListener(onUpdateFilter);
        SearchStore.addUpdateLastPeriodListener(onUpdateLastPeriod);
        // TODO: FIX error searchModel of null
        SearchStore.setSearchModelProperties(JSON.parse(window.localStorage.getItem('searchModel')));
        var searchBar = document.querySelector('.c-search_bar');
        if (UserStore.isInit() && SearchStore.getLastPeriod()) {
            updateFilter();
        }
        if (searchBar) {
            searchBar.classList.add('no-animation');
        }
        setTimeout(function () {
            if (searchBar) {
                searchBar.classList.remove('no-animation');
            }
        }, 5000);
        return function () {
            window.removeEventListener('resize', onWindowResize);
            SearchStore.removeUpdateFiltersListener(onUpdateFilter);
            SearchStore.removeErrorListener(onError);
            SearchStore.removeChangeListener(onSearchUpdate);
            FacetStore.removeFacetsUpdateListener(onUpdateFilter);
            SearchStore.removeUpdateLastPeriodListener(onUpdateLastPeriod);
        };
    }, []);
    useEffect(function () {
        if (page.includes('monitoring') && page !== lastView.current) {
            var lastSearchDate = SearchStore.getSearchDates();
            if (UserStore.isInit() && SearchStore.getLastPeriod()) {
                var todayTo = moment().format('YYYYMMDD');
                var universalFromIsToday = lastSearchDate.to.includes(todayTo);
                if (ClipStore.isInit() && universalFromIsToday) {
                    var todayFrom = moment().startOf('day');
                    var today = moment();
                    var dates = {};
                    dates.universalDateFrom = todayFrom.format('YYYY-MM-DD HH:mm:ss');
                    dates.universalDateTo = today.format('YYYY-MM-DD HH:mm:ss');
                    SearchStore.setSearchDates(dates);
                }
                updateFilter();
            }
        }
        lastView.current = page;
    }, [page]);
    var onUpdateLastPeriod = function () {
        updateFilter();
    };
    var onSearchUpdate = function () {
        setRerender(function (prevState) { return !prevState; });
    };
    var onError = function () {
        SearchStore.totalClips = 0;
    };
    useEffect(function () {
        var _a;
        if (((_a = SearchStore.getQFields()) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            setSearchTypes(initialState);
        }
    }, [SearchStore.getQFields()]);
    var onWindowResize = function () {
        var dashboardContainer = document.querySelector('.c-dashboard_container');
        var clippingContainer = document.querySelector('.c-clipping-container');
        var container = dashboardContainer || clippingContainer;
        if (container && window.innerWidth <= 1250) {
            container.classList.remove('collapsed');
            container.classList.add('expanded');
        }
        else if (container && isFilterOpen) {
            container.classList.add('collapsed');
            container.classList.remove('expanded');
        }
        setSuperPosed(window.innerWidth <= 1250);
    };
    var onUpdateFilter = function (type) {
        if (type === 'updateFacets' || type === 'filterMenu') {
            return;
        }
        setQ(SearchStore.getQ());
    };
    var handleQueryBuilderClick = function () {
        queryBuilderRef.current.handleOpenModal();
    };
    var handleFacetConfigurationClick = function () {
        facetConfigurationRef.current.handleOpenModal();
    };
    var handleFullTextSearchInputChange = function (value) {
        setQ(value);
    };
    var handleKeyPressEnter = function (e) {
        if (e.keyCode === 13 || e.type === 'click') {
            SearchStore.setQ(Q);
            updateFilter();
        }
    };
    var handleCloseFilters = function () {
        setIsFilterOpen(false);
    };
    var facetConfigurationRef = function (ref) {
        facetConfigurationRef.current = ref;
    };
    var updateFilter = function (_, model, loadAllFilters) {
        if (loadAllFilters === void 0) { loadAllFilters = false; }
        clearTimeout(interval.current);
        var updatedModel = model || JSON.parse(JSON.stringify(SearchStore.buildSearchModel()));
        updatedModel = __assign(__assign({}, updatedModel), { loadAllFilters: loadAllFilters, page: page });
        updatedModel.loadAllFilters = true;
        if (page === 'analytics') {
            updatedModel.updateWidget = true;
        }
        interval.current = setTimeout(SearchActionCreators.updateFilter(updatedModel), TIMEOUT_BEFORE_FILTER_UPDATE);
    };
    var containerClasses = classnames('c-search_bar', {
        'search_bar__content--hidden': !isFilterOpen,
        superposed: superPosed,
    });
    var searchModel = SearchStore.searchObject.searchModel;
    var selectedFacets = SearchStore.getSelectedFacetsArray();
    var facetsConfigurantionItems = SearchStore.advancedFilterMenu;
    var pageSubtitle = "".concat(SearchStore.totalClips || 0, " ").concat(formatMessage({
        id: 'Global.articles',
    }, { totalClips: SearchStore.totalClips || 0 }));
    var handleChangeSearchTypesCheckbox = function (event, searchTypesParam) {
        setSearchTypes(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[searchTypesParam] = !prevState[searchTypesParam], _a)));
        });
        var QFields = __spreadArray([], SearchStore.getQFields(), true);
        if (event.target.checked) {
            if (!QFields.includes(searchTypesParam)) {
                SearchStore.setQFields(__spreadArray(__spreadArray([], QFields, true), [searchTypesParam], false));
            }
        }
        else {
            var QFieldsArray = SearchStore.getQFields();
            var index = QFieldsArray.indexOf(searchTypesParam);
            QFieldsArray.splice(index, 1);
            SearchStore.setQFields(QFieldsArray);
        }
    };
    var handleOpenAdvancedSearchModal = function () {
        setOpenAdvancedSearch(true);
    };
    var handlecloseSearchModal = function () {
        setOpenAdvancedSearch(false);
    };
    var handleResetCheckbox = function () {
        setIsAdvancedSearchKeywords(false);
    };
    var QFields = SearchStore.getQFields();
    return (_jsxs(Wrapper, { className: containerClasses, ref: searchBarRef, "$isHidden": !isFilterOpen, "$superposed": superPosed, children: [_jsxs(AdvancedFilterProvider, { children: [_jsx(GlobalSearchbarVars, { isFilterOpen: isFilterOpen, superPosed: superPosed }), _jsxs("div", { className: "search_bar__container", children: [_jsx(QueryBuilder, { updateFilter: updateFilter, ref: queryBuilderRef }), _jsx(AdvancedFilter, { updateFilter: updateFilter }), _jsx(FacetConfiguration, { ref: facetConfigurationRef, updateFilter: updateFilter }), _jsx("div", { className: "article-total", children: _jsx(Typography, { variant: "h3", children: pageSubtitle }) }), _jsxs("section", { className: "view new-scrollbar", children: [_jsxs("div", { className: "filter-labels", children: [_jsx("h2", { className: "filter-title", children: _jsx("strong", { children: _jsx(FormattedMessage, { id: "Insight.widget.sidebar.filters" }) }) }), _jsxs("div", { className: "filters-actions", children: [PermissionStore.roles.includes(ROLES_ENUM.CAN_ORGANIZE_FILTERS) && (_jsxs(ButtonIcon, { disabled: facetsConfigurantionItems &&
                                                            facetsConfigurantionItems.length === 0, 
                                                        // title="Open filters configuration modal"
                                                        title: " ".concat(formatMessage({
                                                            id: 'FacetsConfiguration.modalTitleStrong',
                                                        }), " ").concat(formatMessage({
                                                            id: 'FacetsConfiguration.modalYourTitle',
                                                        })), ariaLabel: " ".concat(formatMessage({
                                                            id: 'FacetsConfiguration.modalTitleStrong',
                                                        }), " ").concat(formatMessage({
                                                            id: 'FacetsConfiguration.modalYourTitle',
                                                        })), onClick: handleFacetConfigurationClick, children: [_jsx("span", { className: "hidden", children: "filter configuration" }), _jsx(BsGear, {})] })), _jsx(Hidden, { smUp: true, children: _jsx(ButtonIcon, { onClick: handleCloseFilters, children: _jsx(GrClose, {}) }) })] })] }), !searchModel.Hash && !searchModel.DraftId && (_jsx(FavouriteSearch, { updateFilter: updateFilter })), _jsxs("div", { children: [_jsxs("div", { className: "advanced-search__keyword-label", children: [_jsxs("div", { children: [isAdvancedSearchKeywords && (_jsx(ButtonIcon, { className: "return-back", onClick: handleResetCheckbox, title: formatMessage({ id: 'Global.back' }), children: _jsx(Svg, { icon: "chevron-left", size: "x-small" }) })), _jsx("span", { children: formatMessage({ id: '_Search.NarrowResults' }) })] }), _jsx(ButtonIcon, { onClick: handleOpenAdvancedSearchModal, title: formatMessage({
                                                            id: 'global.userGuide',
                                                        }), children: _jsx(AiOutlineQuestionCircle, { size: "20" }) })] }), _jsxs("div", { className: "search-bar_full-text-search ".concat(isAdvancedSearchKeywords &&
                                                    'advanced-search__keywords-direction'), children: [(!isAdvancedSearchKeywords && (_jsx(Input, { id: "full-text-search", className: "full-text_search__input", placeholder: formatMessage({ id: 'Global.search' }), onChange: handleFullTextSearchInputChange, onKeyDown: handleKeyPressEnter, value: Q || '' }))) || (_jsxs("div", { className: "search__bar-keywords-group ", children: [_jsx(Textarea, { onChange: handleFullTextSearchInputChange, rows: 10, value: Q || '' }), _jsxs("div", { className: "m-top--medium", children: [_jsx(Checkbox, { checked: searchTypes['headlines.text'] ||
                                                                            QFields.includes('headlines.text'), id: "headlines.text", onChange: function (e) {
                                                                            return handleChangeSearchTypesCheckbox(e, 'headlines.text');
                                                                        }, children: _jsx("span", { children: formatMessage({ id: 'Global.headline' }) }) }), _jsx(Checkbox, { checked: searchTypes['summary.text'] ||
                                                                            QFields.includes('summary.text'), onChange: function (e) {
                                                                            return handleChangeSearchTypesCheckbox(e, 'summary.text');
                                                                        }, id: "summary.text", children: _jsx("span", { children: formatMessage({ id: 'Global.summary' }) }) }), _jsx(Checkbox, { checked: searchTypes.fullText || QFields.includes('fullText'), onChange: function (e) {
                                                                            return handleChangeSearchTypesCheckbox(e, 'fullText');
                                                                        }, id: "fullText", children: _jsx("span", { children: formatMessage({ id: 'Global.fullText' }) }) })] })] })), _jsxs("div", { className: "advanced-serach__keywords-buttons", children: [_jsx(Button, { onClick: function () {
                                                                    return setIsAdvancedSearchKeywords(!isAdvancedSearchKeywords);
                                                                }, className: isAdvancedSearchKeywords
                                                                    ? 'advanced-serach__keywords-cancel'
                                                                    : 'advanced-serach__keywords-button-icon', title: formatMessage({ id: 'SearchBar.searchOptions' }), children: !isAdvancedSearchKeywords ? (_jsx(Svg, { className: "filtersvg", icon: "options", size: "x-large" })) : (_jsx(Button, { onClick: handleResetCheckbox, children: formatMessage({ id: 'Global.close' }) })) }), _jsx(Button, { type: "primary", ariaLabel: formatMessage({
                                                                    id: 'FavouriteSearch.refineMySearch.tooltip',
                                                                }), title: formatMessage({
                                                                    id: 'FavouriteSearch.refineMySearch.tooltip',
                                                                }), className: "drop-down-icon", onClick: handleKeyPressEnter, disabled: isAdvancedSearchKeywords && !Q, children: !isAdvancedSearchKeywords ? (_jsxs(_Fragment, { children: [_jsx(AiOutlineSearch, { size: "24px", className: "full-text-search__icon" }), _jsx("span", { className: "hidden", children: formatMessage({
                                                                                id: 'FavouriteSearch.refineMySearch.tooltip',
                                                                            }) }), _jsx("span", { className: "hidden" })] })) : (_jsx("span", { children: formatMessage({ id: 'Global.search' }) })) })] })] })] }), !isAdvancedSearchKeywords && (_jsxs(_Fragment, { children: [_jsxs("div", { role: "button", tabIndex: 0, className: "create-query-builder createquery", onClick: handleQueryBuilderClick, onKeyPress: handleQueryBuilderClick, title: formatMessage({ id: 'SideBar.createNewQuery' }), children: [_jsx(IoMdColorWand, { className: "builder-svg", size: "19" }), _jsx(FormattedMessage, { id: "SideBar.createNewQuery" })] }), _jsx(Facets, { updateFilter: updateFilter, selectedFacetItemIds: selectedFacets })] }))] })] })] }), openAdvancedSearchModal && (_jsx(AdvancedSearchModal, { isOpenAdvancedSearch: openAdvancedSearchModal, handlecloseSearchModal: handlecloseSearchModal }))] }));
}
SearchBar.propTypes = {
    lastPeriod: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
        logicalDate: PropTypes.number,
    }).isRequired,
};
export default SearchBar;
